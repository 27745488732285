import en from "../locales/en/default";
import nl from "../locales/nl-NL/default";

export default defineI18nConfig(() => {
  return {
    legacy: false,
    fallbackLocale: ["en", "nl"],
    messages: {
      en,
      nl,
    },
  };
});
// https://vue-i18n.intlify.dev/
// https://i18n.nuxtjs.org/getting-started/basic-usage
