import eventSelection from "./eventSelection.json";
import ticketSelection from "./ticketSelection.json";
import general from "./general.json";
import order from "./order.json";
import gender from "./gender.json";
import allergies from "./allergies.json";
import payment from "./payment.json";
import errors from "./errors.json";

export default {
  eventSelection: eventSelection,
  ticketSelection: ticketSelection,
  general: general,
  order: order,
  gender: gender,
  allergies: allergies,
  payment: payment,
  errors: errors,
};
